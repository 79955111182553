import { Container, Prose, SectionText } from '@/atoms';
import { Card, SwiperCustom } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeHistoriesProps } from './types';

/**
 * Histories
 */
export const Histories: FunctionComponent<TypeHistoriesProps> = ({
  className = '',
  caption,
  cutLevel = 50,
  cutLevelMobile = 50,
  desc,
  textColor,
  title,
  cards,
  footer,
  ...props
}: TypeHistoriesProps) => (
  <Container
    cutLevel={cutLevel === null ? 50 : cutLevel}
    cutLevelMobile={cutLevelMobile === null ? 50 : cutLevel}
    overflows={false}
    className={`flex flex-col gap-10 ${className}`}
    {...props}
  >
    <div className="flex flex-col gap-6 lg:flex-row lg:gap-20">
      <div className="flex flex-col gap-14 lg:max-w-[40%]">
        <SectionText
          className="justify-center "
          direction="column"
          textAlign="start"
          title={title}
          desc={desc}
          caption={caption}
          variant={textColor}
        />
      </div>
      <div className=" w-full lg:max-w-[60%]">
        <SwiperCustom
          direction="row"
          scrollbar
          navigation
          gap={16}
          slidesPerView={cards.length < 2 ? 1.6 : 2.3}
          slidesPerViewMobile={1.2}
          variants={textColor}
        >
          {cards.map((card, index) => (
            <Card
              multimediaProps={{ image: card.image }}
              key={index}
              backgroundColor={card.backgroundColor}
              className="h-full"
            >
              <div className="flex flex-col gap-4 text-text-primary">
                {card.name && (
                  <p className="text-body text-body--secondary font-light">
                    {card.name}
                  </p>
                )}
                <p className="text-subtitle text-subtitle--small">
                  {card.testimonial}
                </p>
                <p className="text-body text-body--secondary font-light text-text-secondary">
                  {card.service}
                </p>
              </div>
            </Card>
          ))}
        </SwiperCustom>
      </div>
    </div>
    <>
      {footer && (
        <div className="flex w-full flex-col">
          <Prose
            html={footer}
            as="div"
            className="text-heading text-heading--h5 w-full !text-primary-300"
          />
        </div>
      )}
    </>
  </Container>
);

Histories.displayName = 'Histories';
